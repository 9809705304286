import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import classes from "./blog.module.scss"
import Layout from "../components/layout"
import Image from "gatsby-image"

import { navigate } from "gatsby"

export default function AllBlogs() {
  let data = useStaticQuery(graphql`
    query {
      allDatoCmsBlog(
        sort: { fields: date, order: DESC }
        filter: { locale: { eq: "en" } }
      ) {
        edges {
          node {
            title
            url
            authorName
            date(formatString: "LL")
            featuredImage {
              fluid(maxWidth: 450, maxHeight: 550) {
                ...GatsbyDatoCmsSizes
              }
            }
            bodyNode {
              childMarkdownRemark {
                html
                excerpt
              }
            }
          }
        }
      }
    }
  `)

  const blogs = data.allDatoCmsBlog.edges

  const featuredBlog = blogs[0].node
  const featuredBlogItem = (
    <div
      className={classes.featuredBlog}
      onClick={() => {
        navigate(`/blog/${featuredBlog.url}`)
      }}
      onKeyDown={() => {
        navigate(`/blog/${featuredBlog.url}`)
      }}
      role="link"
      tabIndex={0}
    >
      <div className={classes.featuredBlogImage}>
        <Image fluid={featuredBlog.featuredImage.fluid} />
      </div>
      <div className={classes.titleFeaturedContainer}>
        <div className={classes.cardInfo}>
          <h3 className={classes.featuredTitle}>{featuredBlog.title}</h3>
          <span className={classes.subtitle}>
            <h6 className={classes.author}>by {featuredBlog.authorName}</h6>
            <h6 className={classes.date}>{featuredBlog.date}</h6>
          </span>
        </div>
        {featuredBlog.bodyNode.childMarkdownRemark && (
          <p>{featuredBlog.bodyNode.childMarkdownRemark.excerpt}</p>
        )}
        <div className={classes.cardFooterFeatured}>
          <Link className={classes.link} to={`/blog/${featuredBlog.url}`}>
            Read More {`>`}
          </Link>
        </div>
      </div>
    </div>
  )
  return (
    <Layout pageTitle="Blog">
      <section className={classes.allblogs}>
        <div className={classes.featuredContainer}>
          {featuredBlogItem}
          <div className={classes.featuredRemaining}>
            {blogs.slice(1, 5).map((node, index) => {
              const blog = node.node
              const { title } = blog
              return (
                <div
                  key={index}
                  className={`${classes.blog} ${classes.firstfourblog}`}
                  onClick={() => {
                    navigate(`/blog/${blog.url}`)
                  }}
                  onKeyDown={() => {
                    navigate(`/blog/${blog.url}`)
                  }}
                  role="link"
                  tabIndex={0}
                >
                  <Image fluid={blog.featuredImage.fluid} />
                  <div>
                    <h3 className={classes.blogTitle}>{title}</h3>
                    <div className={classes.cardFooter}>
                      <Link
                        className={classes.linkblog}
                        to={`/blog/${blog.url}`}
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className={classes.blogs}>
          {blogs.slice(5, 13).map((node, index) => {
            const blog = node.node
            const { title } = blog
            return (
              <div
                key={index}
                className={`${classes.blog} ${classes.fourcolumnblog}`}
                onClick={() => {
                  navigate(`/blog/${blog.url}`)
                }}
                onKeyDown={() => {
                  navigate(`/blog/${blog.url}`)
                }}
                role="link"
                tabIndex={0}
              >
                <Image fluid={blog.featuredImage.fluid} />
                <div>
                  <h3 className={classes.blogTitle}>{title}</h3>
                  <div className={classes.cardFooter}>
                    <Link className={classes.linkblog} to={`/blog/${blog.url}`}>
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </section>
    </Layout>
  )
}
